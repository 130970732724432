import React from "react";

import "../CSS/smallNavbar.css";

const SmallNavbar = () => {
  return (
    <>
      <nav>
        <section id="small-nav" className="small-nav">
          <div className="container">
            <div className="row text-white mt-3 mb-3">
              <div className="col-8">
                <i className="fa-regular fa-envelope navbar-icon navbar-icon-email"></i>
                <span className="navbar-text navbar-text-email">
                  <a
                    href="mailto:info@mastercity.com.pk"
                    className="text-decoration-none"
                  >
                    info@mastercity.com.pk
                  </a>
                </span>
                <i className="fa-solid fa-phone-volume navbar-icon navbar-icon-phone"></i>
                <span className="ms-2 navbar-text navbar-text-phone">
                  <a
                    href="tel:0335-0666602-10"
                    className="text-decoration-none"
                  >
                    0335-0666602-10
                  </a>
                </span>
              </div>
              <div className="col-4 d-flex justify-content-end mt-1">
                <a
                  href="https://www.facebook.com/mastercityguj"
                  className="text-decoration-none text-white"
                >
                  <i className="fa-brands fa-facebook-f"></i>
                </a>
                <a
                  href="https://instagram.com/mastercity_pvt_ltd?igshid=MzRlODBiNWFlZA=="
                  className="text-decoration-none text-white"
                >
                  <i className="fa-brands fa-instagram ms-3"></i>
                </a>
                <a
                  href="https://www.youtube.com/@MasterCitypvt"
                  className="text-decoration-none text-white"
                >
                  <i className="fa-brands fa-youtube ms-3"></i>
                </a>
              </div>
            </div>
          </div>
        </section>
      </nav>
    </>
  );
};

export default SmallNavbar;
