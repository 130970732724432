import React from "react";

import "../CSS/gallery.css";

const Gallery = () => {
  return (
    <>
      <section id="gallery" className="gallery-main">
        <div className="container container-fluid">
          <div className="row text-center">
            <div className="col-12">
              <h5 className="gallery-heading">Master City Gallery</h5>
            </div>
            <div className="col-12">
              <h3 className="gallery-title">Development Process</h3>
            </div>
          </div>
          <div className="row g-5">
            <div className="col-md-6 col-lg-3">
              <img
                src="assets/images/development-process/development-process-1.jpg"
                alt=""
                className="development-process-1"
              />
            </div>
            <div className="col-md-6 col-lg-3">
              <img
                src="assets/images/development-process/development-process-2.jpg"
                alt=""
                className="development-process-2"
              />
            </div>
            <div className="col-md-6 col-lg-6">
              <img
                src="assets/images/development-process/development-process-3.jpg"
                alt=""
                className="development-process-3"
              />
            </div>
            <div className="col-md-6 col-lg-6">
              <img
                src="assets/images/development-process/development-process-4.jpg"
                alt=""
                className="development-process-4"
              />
            </div>
            <div className="col-md-6 col-lg-3">
              <img
                src="assets/images/development-process/development-process-5.jpg"
                alt=""
                className="development-process-5"
              />
            </div>
            <div className="col-md-6 col-lg-3">
              <img
                src="assets/images/development-process/development-process-6.jpg"
                alt=""
                className="development-process-6"
              />
            </div>
          </div>
          <div className="row text-center">
            <div className="col-12">
              <button className="rounded-button">View All</button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Gallery;
