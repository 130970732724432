import React, { useState } from "react";
import {
  Container,
  Nav,
  Navbar,
  Offcanvas,
  NavDropdown,
} from "react-bootstrap";

import "../CSS/header.css";

const Header = () => {
  const [offcanvasVisible, setOffcanvasVisible] = useState(false);

  const handleLinkClick = (e, targetId) => {
    e.preventDefault();
    setOffcanvasVisible(false);
    setTimeout(() => {
      handleSmoothScroll(targetId);
    }, 500);
  };

  const handleSmoothScroll = (targetId) => {
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <header id="header" className="row g-0">
        <div className="col-2">
          <div className="logo">
            <img src="assets/logo/master-city-logo.png" alt="" />
          </div>
        </div>
        <div className="col-10">
          <Navbar
            bg="black"
            variant="dark"
            expand="lg"
            className="navbar navbar-with-background"
          >
            <Container fluid>
              <Navbar.Toggle
                aria-controls="offcanvasNavbar-expand-lg"
                onClick={() => setOffcanvasVisible(!offcanvasVisible)}
              />
              <Navbar.Offcanvas
                placement="end"
                id="offcanvasNavbar-expand-lg"
                show={offcanvasVisible}
                onHide={() => setOffcanvasVisible(false)}
              >
                <Offcanvas.Header closeButton closeVariant="white">
                  <Offcanvas.Title id="offcanvasNavbarLabel-expand-lg">
                    <img
                      src="assets/logo/master-city-logo.png"
                      alt=""
                      className="d-inline-block w-50 img-fluid"
                    />
                  </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <Nav className="justify-content-evenly flex-grow-1 pe-3 me-5">
                    <Nav.Link href="/" onClick={(e) => handleLinkClick(e, "/")}>
                      Home
                    </Nav.Link>
                    <Nav.Link
                      href="#who-we-are"
                      onClick={(e) => handleLinkClick(e, "who-we-are")}
                    >
                      Who we are
                    </Nav.Link>
                    <NavDropdown
                      title="Master City Projects"
                      id="offcanvasNavbarDropdown-expand-lg"
                    >
                      <NavDropdown.Item
                        href="#projects"
                        onClick={(e) => handleLinkClick(e, "projects")}
                      >
                        Gujranwala
                      </NavDropdown.Item>
                      <NavDropdown.Divider />
                      <NavDropdown.Item
                        href="#projects"
                        onClick={(e) => handleLinkClick(e, "projects")}
                      >
                        Rawalpindi
                      </NavDropdown.Item>
                      <NavDropdown.Divider />
                      <NavDropdown.Item
                        href="#projects"
                        onClick={(e) => handleLinkClick(e, "projects")}
                      >
                        Master Homes
                      </NavDropdown.Item>
                    </NavDropdown>
                    <Nav.Link
                      href="#payment-plan"
                      onClick={(e) => handleLinkClick(e, "payment-plan")}
                    >
                      Payment Plan
                    </Nav.Link>
                    <Nav.Link disabled>E-tracking</Nav.Link>
                    <Nav.Link
                      href="#gallery"
                      onClick={(e) => handleLinkClick(e, "gallery")}
                    >
                      Gallery
                    </Nav.Link>
                    <Nav.Link
                      href="#contact"
                      onClick={(e) => handleLinkClick(e, "contact")}
                    >
                      Contact
                    </Nav.Link>
                  </Nav>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </Container>
          </Navbar>
        </div>
      </header>
    </>
  );
};

export default Header;
