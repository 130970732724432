import React from "react";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import "../CSS/masterCityProjects.css";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 2,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 767, min: 464 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

const sliderImageUrl = [
  //First image url
  {
    url: "assets/images/master-city-project/projects-1.png",
    title: "Master City",
    heading: "Gujranwala",
  },
  //Second image url
  {
    url: "assets/images/master-city-project/projects-2.png",
    title: "Master",
    heading: "Homes",
  },
  //Third image url
  {
    url: "assets/images/master-city-project/projects-3.png",
    title: "Master City",
    heading: "Rawalpindi",
  },
];

const MasterCityProjects = () => {
  return (
    <>
      <section id="projects" className="projects-main">
        <div className="container">
          <h3 className="master-city-projects-head text-center text-black">
            Master City Projects
          </h3>
          <div className="parent">
            <Carousel
              ssr={true}
              infinite={true}
              autoPlay={true}
              showDots={false}
              swipeable={true}
              draggable={true}
              partialVisible={false}
              responsive={responsive}
              dotListClass="custom-dot-list-style"
              removeArrowOnDeviceType={["tablet", "mobile"]}
            >
              {sliderImageUrl.map((imageUrl, index) => {
                return (
                  <div className="slider" key={index}>
                    <img src={imageUrl.url} alt="movie" />
                    <div className="carousel-text">
                      <h4>{imageUrl.title}</h4>
                      <h2>{imageUrl.heading}</h2>
                    </div>
                  </div>
                );
              })}
            </Carousel>
          </div>
        </div>
      </section>
    </>
  );
};

export default MasterCityProjects;
