import React from "react";
import ScrollToTop from "react-scroll-to-top";

import About from "./Components/About";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import Gallery from "./Components/Gallery";
import Contact from "./Components/Contact";
import WhoWeAre from "./Components/WhoWeAre";
import Features from "./Components/Features";
import MainBanner from "./Components/MainBanner";
import Advantages from "./Components/Advantages";
import Rawalpindi from "./Components/Rawalpindi";
import SmallNavbar from "./Components/SmallNavbar";
import MasterCityProjects from "./Components/MasterCityProjects";

import "./App.css";

const App = () => {
  return (
    <>
      <div className="overflow-hidden">
        <ScrollToTop smooth />
        <SmallNavbar />
        <Header />
        <MainBanner />
        <WhoWeAre />
        <MasterCityProjects />
        <Features />
        <Advantages />
        <Gallery />
        <About />
        {/* <Rawalpindi /> */}
        <Contact />
        <Footer />
      </div>
    </>
  );
};

export default App;
