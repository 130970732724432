import React, { useState } from "react";

import "../CSS/contact.css";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    comments: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData); // You can perform further actions with the form data here
  };
  return (
    <>
      <section id="contact" className="contact-main">
        <div className="container">
          <div className="row">
            <div className="col-sm-6">
              <div className="row">
                <div className="col-12">
                  <h5 className="contact-heading">Master City Authority</h5>
                </div>
                <div className="col-12">
                  <h3 className="contact-title">Contact us</h3>
                </div>
                <div className="col-12">
                  <p className="contact-desc">
                    Master City PVT Ltd. Opposite Peoples Colony, Sialkot Bypass
                    Road Gujranwala.
                  </p>
                </div>
                <div className="col-12">
                  <form onSubmit={handleSubmit} className="form-container">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        placeholder="Your Name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        placeholder="Your Email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="tel"
                        id="phone"
                        name="phone"
                        placeholder="Your Phone Number"
                        value={formData.phone}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <textarea
                        id="comments"
                        name="comments"
                        placeholder="Comments"
                        value={formData.comments}
                        onChange={handleChange}
                      ></textarea>
                    </div>
                    <button type="submit" className="submit-button">
                      Submit
                    </button>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-sm-6 contact-img d-flex align-items-center justify-content-center">
              <img
                src="assets/images/contact-us/contact.jpg"
                alt=""
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
