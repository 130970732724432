import React from "react";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import "../CSS/features.css";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 3,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 767, min: 464 },
    items: 2,
    slidesToSlide: 1, // optional, default to 1.
  },
};

const sliderImageUrl = [
  //First image url
  {
    url: "assets/other-assets/features-images/features-1.png",
  },
  //Second image url
  {
    url: "assets/other-assets/features-images/features-2.png",
  },
  //Third image url
  {
    url: "assets/other-assets/features-images/features-3.png",
  },
  //Fourth image url
  {
    url: "assets/other-assets/features-images/features-4.png",
  },
  //Fifth image url
  {
    url: "assets/other-assets/features-images/features-5.png",
  },
];

const Features = () => {
  return (
    <>
      <div className="features-main">
        <div className="container features-background">
          <div className="row text-center">
            <div className="col-12">
              <h5 className="features-heading">AMENITIES & Salient Feature</h5>
            </div>
            <div className="col-12">
              <h3 className="features-title">Luxury at a Glance</h3>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="features-slider-main">
                <Carousel
                  responsive={responsive}
                  autoPlay={true}
                  swipeable={true}
                  draggable={true}
                  showDots={false}
                  infinite={true}
                  partialVisible={false}
                  removeArrowOnDeviceType={["tablet", "mobile"]}
                >
                  {sliderImageUrl.map((imageUrl, index) => {
                    return (
                      <div className="features-slider" key={index}>
                        <img src={imageUrl.url} alt="movie" />
                      </div>
                    );
                  })}
                </Carousel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Features;
