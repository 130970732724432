import React from "react";

import "../CSS/whoWeAre.css";

const WhoWeAre = () => {
  return (
    <>
      <section id="who-we-are" className="who-we-are-main">
        <div className="container">
          <div className="row">
            <div className="col-6">
              <div className="row no-gutters">
                <div className="col-md-6 d-flex flex-column justify-content-center">
                  <div className="who-we-are-1">
                    <img
                      src="assets/images/who-we-are/who-we-are-1.jpg"
                      alt=""
                      className="d-block w-100 img-fluid"
                    />
                  </div>
                  <div className="who-we-are-2">
                    <img
                      src="assets/images/who-we-are/who-we-are-2.jpg"
                      alt=""
                      className="d-block w-100 img-fluid"
                    />
                  </div>
                </div>
                <div className="col-md-6 d-flex flex-column justify-content-center align-items-center">
                  <div className="group-6">
                    <img
                      src="assets/other-assets/group-6.png"
                      alt=""
                      className="d-block w-100 img-fluid"
                    />
                  </div>
                  <div className="who-we-are-3">
                    <img
                      src="assets/images/who-we-are/who-we-are-3.png"
                      alt=""
                      className="d-block w-100 img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6">
              <h5 className="who-we-are-head">Who we are</h5>
              <h2 className="who-we-are-title">
                The Master City Pvt Ltd is well known name in building a new
                living community with standard that provides a good environment
                for its residents.{" "}
              </h2>
              <p className="text-black">
                The Master City has managed to be one of the key contributors to
                the quality of life for people of Pakistan. The core of this
                success has always lied in our winning corporate culture; that
                promotes growth and fosters innovation; and our customer-driven
                strategy, that is guided by quality, creative solutions, and a
                never-ending search for better ways to serve our clients.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WhoWeAre;
