import React from "react";

import "../CSS/about.css";

const About = () => {
  return (
    <section id="about" className="about-main">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h3 className="about-heading">About Legacy</h3>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-lg-3 col-md-4 col-sm-6 d-flex align-items-center justify-content-center">
            <img
              src="assets/images/our-legacy/about-1.jpg"
              alt=""
              className="about-1 img-fluid"
            />
          </div>
          <div className="col-lg-9 col-md-8 col-sm-6">
            <div className="row">
              <div className="col-lg-12">
                <p className="text-black">
                  43 years before, in 1975 Haji Sheikh Muhammad Tufail came to
                  Gujranwala from Multan at a time when he was engaged in
                  Stainless Steel business. His only assets at the time were a
                  clear vision and unwavering trust on Allah. Haji Sheikh
                  Muhammad Tufail, powered by a firm commitment, laid the
                  foundations of Master Group. Setting was precedents in varied
                  industries; he pioneered a legacy which serves as a beacon for
                  the coming generation, even today. It was his wisdom and
                  farsightedness that enabled Master Group not only in creating
                  an industrial history but to become a trusted brand across
                  Pakistan.
                </p>
              </div>
              <div className="col-lg-6 col-md-6">
                <img
                  src="assets/images/our-legacy/about-2.jpg"
                  alt=""
                  className="img-fluid"
                />
              </div>
              <div className="col-lg-6 col-md-6 mb-5">
                <img
                  src="assets/images/our-legacy/about-3.jpg"
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
