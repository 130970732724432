import React from "react";
import { MdLocalLaundryService, MdOutlineAddRoad } from "react-icons/md";
import { GiParkBench } from "react-icons/gi";
import { BsBuildingsFill } from "react-icons/bs";
import { AiOutlineCar } from "react-icons/ai";

import "../CSS/advantages.css";

const Advantages = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <h4 className="advantages-heading">Advantages you cannot ignore</h4>
        </div>
      </div>
      <div className="row">
        <div className="col advantages-col">
          <div className="card">
            <div className="card-body text-black text-center d-flex align-items-center">
              <i className="fa-solid fa-person-running advantages-icons"></i>
              <h5 className="card-title">Shock proof jogging track</h5>
            </div>
          </div>
          <div className="card">
            <div className="card-body text-black text-center d-flex align-items-center">
              <i className="fa-solid fa-person-biking advantages-icons"></i>
              <h5 className="card-title">Unique Cycling Park</h5>
            </div>
          </div>
          <div className="card">
            <div className="card-body text-black text-center d-flex align-items-center">
              <MdLocalLaundryService className="advantages-icons" />
              <h5 className="card-title">Laundromats</h5>
            </div>
          </div>
          <div className="card">
            <div className="card-body text-black text-center d-flex align-items-center">
              <GiParkBench className="advantages-icons" />
              <h5 className="card-title">Glow in Dark Park</h5>
            </div>
          </div>
          <div className="card">
            <div className="card-body text-black text-center d-flex align-items-center">
              <BsBuildingsFill className="advantages-icons" />
              <h5 className="card-title">Commercial Markets</h5>
            </div>
          </div>
          <div className="card">
            <div className="card-body text-black text-center d-flex align-items-center">
              <AiOutlineCar className="advantages-icons" />
              <h5 className="card-title">Separate Entrances</h5>
            </div>
          </div>
          <div className="card">
            <div className="card-body text-black text-center d-flex align-items-center">
              <MdOutlineAddRoad className="advantages-icons" />
              <h5 className="card-title">Gujranwala expressway</h5>
            </div>
          </div>
          <div className="card" style={{ backgroundColor: "#27213D" }}>
            <div className="card-body text-white d-flex justify-content-center">
              <h3 className="card-title">
                More
                <br />
                Advantages
              </h3>
              <i className="fa-solid fa-arrow-right advantages-icons-arrow mt-3 ms-3"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Advantages;
