import React from "react";

import "../CSS/footer.css";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer">
      <div className="container footer-background">
        <div className="row justify-content-center g-5">
          <div className="col-lg-3">
            <h2>About Us</h2>
            <p>
              Master City is a most premium commercial project in Gujranwala.
              Master City is located at Opposite Peoples Colony Sialkot Bypass
              Road، Gujranwala; Pakistan surrounded by pollution-free all
              necessities of modern life activities.
            </p>
            <div className="social-icons">
              <a
                href="https://www.facebook.com/mastercityguj"
                className="text-decoration-none text-white"
              >
                <i className="fa-brands fa-facebook-f"></i>
              </a>
              <a
                href="https://instagram.com/mastercity_pvt_ltd?igshid=MzRlODBiNWFlZA=="
                className="text-decoration-none text-white"
              >
                <i className="fa-brands fa-instagram ms-3"></i>
              </a>
              <a
                href="https://www.youtube.com/@MasterCitypvt"
                className="text-decoration-none text-white"
              >
                <i className="fa-brands fa-youtube ms-3"></i>
              </a>
            </div>
            <p>
              &copy; {currentYear} Copyright Capital Avenue. All rights
              reserved.
            </p>
          </div>
          <div className="col-lg-3">
            <h2>Quick Links</h2>
            <ul className="list-unstyled">
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <a href="#about">About Master city</a>
              </li>
              <li>
                <a href="#gallery">Gallery</a>
              </li>
              <li>
                <a href="#contact">Contact</a>
              </li>
            </ul>
          </div>
          <div className="col-lg-3">
            <h2>Main Projects</h2>
            <ul className="list-unstyled">
              <li>
                <a href="#projects">Master City Projects</a>
              </li>
              <li>
                <a href="#payment-plan">Payment Plan</a>
              </li>
              <li>
                <a href="#contact">Location Map</a>
              </li>
              <li>
                <a href="/" aria-disabled>
                  E-Tracking
                </a>
              </li>
            </ul>
          </div>
          <div className="col-lg-3">
            <h2>Contact Us</h2>
            <div className="address">
              <i className="fa-solid fa-location-dot"></i>
              <a
                href="https://goo.gl/maps/BeouF2V1CE6GiTd57"
                className="text-decoration-none text-white"
              >
                <p>
                  Master City PVT Ltd. Opposite Peoples Colony, Sialkot Bypass
                  Road Gujranwala.
                </p>
              </a>
            </div>
            <div className="phone">
              <i className="fa-solid fa-phone-volume navbar-icon navbar-icon-phone"></i>
              <a
                href="tel:0335-0666602-10"
                className="text-decoration-none text-white"
              >
                0335-0666602-10
              </a>
            </div>
            <div className="email">
              <i className="fa-regular fa-paper-plane"></i>
              <a
                href="mailto:info@mastercity.com.pk"
                className="text-decoration-none text-white"
              >
                info@mastercity.com.pk
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-12 d-flex align-items-center justify-content-center">
          <div className="row">
            <div className="col-lg-auto col-md-12">
              <label htmlFor="exampleInputEmail1" className="col-form-label">
                Newsletter
              </label>
            </div>
            <div className="col-lg-auto col-md-6">
              <input
                type="email"
                id="exampleInputEmail1"
                className="form-control"
                placeholder="Your email address"
              />
            </div>
            <div className="col-lg-auto col-md-6">
              <button className="btn btn-primary">Subscribe</button>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
